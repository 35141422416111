import * as React from "react";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useRouter } from "next/router";
import AnalyticsContext from "@/utils/AnalyticsProvider";

export const NavBarBookPlacementButton = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const analytics = React.useContext(AnalyticsContext);

  const router = useRouter();
  const handleBookPlacementTest = (e) => {
    e.preventDefault();
    analytics.action("book-test-button-header-clicked");
    router.push("/BookTest");
  };

  return (
    <>
      {router.pathname !== "/BookTest" && router.pathname !== "/Courses" && (
        <Button
          onClick={handleBookPlacementTest}
          variant="contained"
          component="a"
          href="/BookTest"
          color="secondary"
          size={isSm ? "small" : "large"}
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          Book a Placement Test
        </Button>
      )}
    </>
  );
};
