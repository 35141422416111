import React from "react";
import Image from "next/image";
import Link from "next/link";
import SelectionContext from "@/utils/SelectionProvider";
import languages from "@/data/languages";
import paths from "@/data/paths";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { useCookies } from "react-cookie";
import { Typography } from "@mui/material";
import ipapi from "ipapi.co";
import AnalyticsContext from "@/utils/AnalyticsProvider";

const DutchBanner = () => {
  const [country, setCountry] = React.useState("");
  const { setLanguage, setPath } = React.useContext(SelectionContext);
  const [cookies, setCookie] = useCookies(["inburgeringsexamenShown"]);
  const analytics = React.useContext(AnalyticsContext);

  const router = useRouter();
  React.useEffect(() => {
    ipapi.location((loc) => {
      setCountry(loc.country_code);
    });
  }, []);

  return (
    <div>
      {/* country === "QA" ||  */}
      {country === "NL" && (
        <motion.div
          className="flex items-center justify-center w-full gap-2 p-2 overflow-hidden bg-yellow-100"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "4rem" }}
          transition={{ duration: 0.4 }}
        >
          <Image
            src="/flags/nl.svg"
            alt="Netherlands Flag"
            width={30}
            height={30}
          />
          <Typography variant="body2" className="text-gray-700">
            {
              "We offer Dutch Naturalization Exam (Inburgeringsexamen) preparation course."
            }
          </Typography>
          <Link
            href="/Courses"
            onClick={(e) => {
              e.preventDefault();
              setCookie("inburgeringsexamenShown", true, {
                path: "/",
                maxAge: 1707109200,
                sameSite: true,
              });
              setLanguage(languages.find((l) => l.value === "nl_NL"));
              setPath(paths.find((p) => p.id === 4));
              analytics.action("dutch_banner_click");
              router.push("/Courses");
            }}
          >
            <Typography variant="body2">Learn More</Typography>
          </Link>
        </motion.div>
      )}
    </div>
  );
};

export default DutchBanner;
