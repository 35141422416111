import { AnalyticsProvider } from "@/utils/AnalyticsProvider";
import Layout from "@/components/Layout";
import createEmotionCache from "@/utils/createEmotionCache";
// import { ThemeProvider, CssBaseline } from "@mui/material";
// import { lightTheme, darkTheme } from "@/styles/themes";
import { MultiThemeProvider } from "@/utils/MultiThemeProvider";
import { SnackbarProvider } from "notistack";

// TailwindCSS Styles
import "tailwindcss/tailwind.css";
// Globals Styles
import "@/styles/globals.scss";

import { CacheProvider } from "@emotion/react";
import { SelectionProvider } from "@/utils/SelectionProvider";
// import SplashScreen from "@/components/Layout/SplashScreen";
import { DefaultSeo, SocialProfileJsonLd } from "next-seo";
import SEO, { socialProfile } from "../next-seo.config";

const clientSideEmotionCache = createEmotionCache();

export default function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  return (
    <CacheProvider value={emotionCache}>
      <MultiThemeProvider>
        <AnalyticsProvider
          googleAnalyticsTrackingID="G-1ENWTZRGM1"
          requireConsent="eu"
        >
          <SelectionProvider>
            <SnackbarProvider maxSnack={3} autoHideDuration={6000}>
              {/* <SplashScreen logo="/Logo.svg" /> */}
              <DefaultSeo {...SEO} />
              <SocialProfileJsonLd {...socialProfile} />
              <Layout defaultSidebarOpen={false}>
                <Component {...pageProps} />
              </Layout>
            </SnackbarProvider>
          </SelectionProvider>
        </AnalyticsProvider>
      </MultiThemeProvider>
    </CacheProvider>
  );
}
