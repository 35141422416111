import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
// import Link from "@mui/material/Link";
// import { Link as RouterLink } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import ThemeSelector from "@/utils/MultiThemeProvider";
import Backdrop from "@mui/material/Backdrop";
import ManageConsentButton from "@/utils/AnalyticsProvider/ManageConsentButton";

const SidebarNav = styled("nav")(({ theme }) => ({
  // background: "#fff",
  boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
  width: "100%",
  maxWidth: "300px",
  padding: "30px 10px",
  height: "100vh",
  // display: "flex",
  // justifyContent: "center",
  position: "fixed",
  top: 0,
  left: 0,
  transition: "350ms",
  zIndex: theme.zIndex.drawer + 2,
  overflowY: "auto",
}));

// const SidebarWrap = styled("div")(() => ({
//   width: "100%",
// }));

const Sidebar = ({
  toogleActive,
  active,
  enableBackdrop = false,
  defaultSidebarOpen,
}) => {
  return (
    <>
      {enableBackdrop && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={active}
          onClick={toogleActive}
        />
      )}
      <nav>
        <SidebarNav className="LeftSidebarNav">
          <div className="flex flex-wrap-reverse items-center justify-between mb-5">
            <ThemeSelector />
            <IconButton
              onClick={toogleActive}
              aria-label="close sidebar"
              size="small"
              // sx={{
              //   background: "rgb(253, 237, 237)",
              //   display: { lg: "none" },
              // }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <div className="flex flex-col w-full items-center justify-center mb-5">
            <div className="w-full">
              {SidebarData.map((item, index) => {
                return (
                  <SubMenu
                    item={item}
                    key={index}
                    toogleActive={toogleActive}
                    defaultSidebarOpen={defaultSidebarOpen}
                  />
                );
              })}
            </div>
            <div className="w-full flex flex-col p-2 absolute bottom-5">
              <ManageConsentButton toogleActive={toogleActive} />
            </div>
          </div>
        </SidebarNav>
      </nav>
    </>
  );
};

export default Sidebar;
