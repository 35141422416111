import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { Comfortaa, Roboto } from "next/font/google";
import vars from "@/styles/themes.module.scss";

const rootElement = () => document.getElementById("__next");

// Import theme icons
import BrightnessAutoIcon from "@mui/icons-material/BrightnessAuto";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ContrastIcon from "@mui/icons-material/Contrast";

const comfortaa = Comfortaa({
  weight: ["300", "400", "500"],
  subsets: ["latin"],
});

const roboto = Roboto({
  weight: ["400", "500", "700"],
  subsets: ["latin", "latin-ext"],
});
const headings = ["h1", "h2", "h3", "h4", "h5", "h6"];
const headingFontArray = headings.slice(0, headings.indexOf("h4") + 1); // Last heading to use headingFont

const headingFont = comfortaa;
const textFont = roboto;

const typography = {};

typography.h1 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: headingFontArray.includes("h1")
    ? headingFont.style.fontFamily
    : textFont.style.fontFamily,
  fontWeight: 300,
  // fontSize: "4.5rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "2.5rem",
  // },
  // color: theme.palette.primary.main,
};

typography.h2 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: headingFontArray.includes("h2")
    ? headingFont.style.fontFamily
    : textFont.style.fontFamily,
  fontWeight: 400,
  // fontSize: "3rem",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "2.25rem",
  // },
  // color: theme.palette.primary.main,
};
typography.h3 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: headingFontArray.includes("h3")
    ? headingFont.style.fontFamily
    : textFont.style.fontFamily,
  fontWeight: 500,
  // fontSize: "2.25rem",
  // color: theme.palette.primary.main,
};
typography.h4 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: headingFontArray.includes("h4")
    ? headingFont.style.fontFamily
    : textFont.style.fontFamily,
  fontWeight: 500,
  // fontSize: "2.125rem",
  // color: theme.palette.primary.main,
};
typography.h5 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: headingFontArray.includes("h5")
    ? headingFont.style.fontFamily
    : textFont.style.fontFamily,
  fontWeight: 500,
  // fontSize: "1.5rem",
};
typography.h6 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: headingFontArray.includes("h6")
    ? headingFont.style.fontFamily
    : textFont.style.fontFamily,
  fontWeight: 500,
  // fontSize: "1.25rem",
};
typography.subtitle1 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 400,
  // fontSize: "1rem",
};
typography.subtitle2 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 500,
  // fontSize: "0.875rem",
};
typography.body1 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 400,
  // fontSize: "1rem",
};
typography.body2 = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 400,
  // fontSize: "0.875rem",
};
typography.button = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 500,
  // fontSize: "0.875rem",
};
typography.caption = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 400,
  // fontSize: "0.75rem",
  // lineHeight: "1.66",
  // letterSpacing: "0.03333em",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
typography.overline = {
  // fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: textFont.style.fontFamily,
  fontWeight: 400,
  // fontSize: "0.75rem",
  // lineHeight: "2.66",
  // letterSpacing: "0.08333em",
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "1.75rem",
  // },
};
// theme.components.MuiButton.styleOverrides.root.backgroundColor = "red";

const globalTheme = responsiveFontSizes(
  createTheme({
    typography: typography,
    components: {
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  })
);

// Create a theme instance.
const lightTheme = createTheme({
  ...globalTheme,
  palette: {
    mode: "light",
    // contrastThreshold: 4.5,
    text: {
      black: vars.textBlack,
      white: vars.textWhite,
      primary: vars.textDark,
      secondary: vars.textLight,
      disabled: "#5B5B98",
      hint: "#5B5B98",
    },
    primary: {
      main: vars.primary,
      light: vars.primaryLight,
      dark: vars.primaryDark,
    },
    secondary: {
      main: vars.secondary,
      light: vars.secondaryLight,
      dark: vars.secondaryDark,
    },
    accent1: {
      main: vars.accent1,
      light: vars.accent1Light,
      dark: vars.accent1Dark,
    },
    accent2: {
      main: vars.accent2,
      light: vars.accent2Light,
      dark: vars.accent2Dark,
    },
    success: {
      main: vars.success,
    },
    info: {
      main: vars.info,
    },
    warning: {
      main: vars.warning,
    },
    danger: {
      main: vars.danger,
    },
    error: {
      main: vars.error,
    },
    appBar: {
      main: vars.appBarBackground,
      contrastText: vars.textWhite,
    },
    background: {
      default: vars.htmlBackground,
    },
    // dark: {
    //   main: "#260944",
    // },
    // linkedIn: {
    //   main: "#0a66c2",
    //   contrastText: "#fff",
    // },
  },
});

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: "dark",
    // contrastThreshold: 4.5,
    text: {
      black: vars.darkTextBlack,
      white: vars.darkTextWhite,
      primary: vars.darkTextDark,
      secondary: vars.darkTextLight,
      disabled: "#5B5B98",
      hint: "#5B5B98",
    },
    primary: {
      main: vars.darkPrimary,
      light: vars.darkPrimaryLight,
      dark: vars.darkPrimaryDark,
    },
    secondary: {
      main: vars.darkSecondary,
      light: vars.darkSecondaryLight,
      dark: vars.darkSecondaryDark,
    },
    accent1: {
      main: vars.darkAccent1,
      light: vars.darkAccent1Light,
      dark: vars.darkAccent1Dark,
    },
    accent2: {
      main: vars.darkAccent2,
      light: vars.darkAccent2Light,
      dark: vars.darkAccent2Dark,
    },
    success: {
      main: vars.success,
    },
    info: {
      main: vars.info,
    },
    warning: {
      main: vars.warning,
    },
    danger: {
      main: vars.danger,
    },
    error: {
      main: vars.error,
    },
    appBar: {
      main: vars.darkAppBarBackground,
      contrastText: vars.darkTextWhite,
    },
    background: {
      default: vars.darkHtmlBackground,
    },
  },
});

const highContrastTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: "light",
    // contrastThreshold: 4.5,
    text: {
      black: vars.highContrastTextBlack,
      white: vars.highContrastTextWhite,
      primary: vars.highContrastTextDark,
      secondary: vars.highContrastTextLight,
      disabled: "#5B5B98",
      hint: "#5B5B98",
    },
    primary: {
      main: vars.highContrastPrimary,
      light: vars.highContrastPrimaryLight,
      dark: vars.highContrastPrimaryDark,
    },
    secondary: {
      main: vars.highContrastSecondary,
      light: vars.highContrastSecondaryLight,
      dark: vars.highContrastSecondaryDark,
    },
    accent1: {
      main: vars.highContrastAccent1,
      light: vars.highContrastAccent1Light,
      dark: vars.highContrastAccent1Dark,
    },
    accent2: {
      main: vars.highContrastAccent2,
      light: vars.highContrastAccent2Light,
      dark: vars.highContrastAccent2Dark,
    },
    success: {
      main: vars.success,
    },
    info: {
      main: vars.info,
    },
    warning: {
      main: vars.warning,
    },
    danger: {
      main: vars.danger,
    },
    error: {
      main: vars.error,
    },
    appBar: {
      main: vars.highContrastAppBarBackground,
      contrastText: vars.highContrastTextWhite,
    },
    background: {
      default: vars.highContrastHtmlBackground,
    },
  },
});

const themes = {
  auto: { name: "Auto", icon: BrightnessAutoIcon, theme: globalTheme },
  light: { name: "Light Theme", icon: LightModeIcon, theme: lightTheme },
  dark: { name: "Dark Theme", icon: DarkModeIcon, theme: darkTheme },
  "high-contrast": {
    name: "High Contrast Theme",
    icon: ContrastIcon,
    theme: highContrastTheme,
  },
};

// export { lightTheme, darkTheme };
export default themes;
