// Next-SEO Configuration. Used by next-seo package.

const config = {
  titleTemplate: "%s | ELOQUENCE | Virtual Face-to-Face Language Courses",
  facebook: { appId: "EloqLanguages" },
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://eloq.nl/",
    siteName: "ELOQUENCE",
    urlTemplate: "https://eloq.nl/%s",
  },
  //   twitter: {
  //     handle: "@handle",
  //     site: "@site",
  //     cardType: "summary_large_image",
  //   },
};

const socialProfile = {
  type: "Organization",
  name: "Eloquence",
  url: "https://eloq.nl/",
  sameAs: [
    "https://www.facebook.com/EloqLanguages",
    "https://www.instagram.com/eloquence.languagelearning/",
    "https://www.tiktok.com/@eloquence.lang.learning?_t=8eSZnzpTRPW&_r=1",
  ],
};

export default config;
export { socialProfile };
