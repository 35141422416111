/* eslint-disable @next/next/no-img-element */
import React from "react";

const BuiltByW = () => {
  // const [referrer, setReferrer] = React.useState("");
  // React.useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setReferrer(window.location.hostname);
  //   }
  // }, []);
  return (
    <div
      className="absolute right-0 bottom-0 flex items-center justify-center text-md font-medium text-gray-700  no-underline bg-white rounded-tl-lg shadow-md" // cursor-pointer hover:text-gray-700
      // href={`https://waleed.dev/?referrer=${referrer}`}
      // target="_blank"
      // rel="noopener noreferrer"
    >
      <div className="flex items-center justify-center p-1 px-4">
        {/* <img
          className="mx-1 mb-1"
          src="https://waleed.dev/W-Logo.svg"
          alt="waleed.dev"
          style={{
            width: "20px",
            height: "16px",
          }}
        /> */}
        {"Built by waleed.dev"}
      </div>
    </div>
  );
};

export default BuiltByW;
