import React from "react";
import Button from "@mui/material/Button";
import AnalyticsContext from ".";

const ManageConsentButton = ({ toogleActive }) => {
  const { setConsentDialogOpen } = React.useContext(AnalyticsContext);
  return (
    <Button
      onClick={() => {
        setConsentDialogOpen(true);
        toogleActive();
      }}
    >
      Cookie Preferences
    </Button>
  );
};

export default ManageConsentButton;
