import React from "react";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { GMobiledataTwoTone } from "@mui/icons-material";

const Logo = ({ height }) => {
  const variants = {
    text: {
      hidden: {
        opacity: 0,
        x: -60,
      },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 1,
        },
      },
    },
    symbol: {
      hidden: {
        opacity: 0,
        y: -50,
        rotate: 720,
      },
      visible: {
        opacity: 1,
        y: 0,
        rotate: 0,
        transition: {
          type: "spring",
          stiffness: 500,
          damping: 15,
          mass: 2,
        },
      },
    },
  };

  return (
    <svg
      version="1.1"
      id="EloquenceLogo"
      fill="white"
      height={height}
      x="0px"
      y="0px"
      viewBox="0 0 415.722 139.849"
      overflow="visible"
      display="block"
    >
      <motion.g
        id="ELOQUENCE"
        // variants={variants.text}
        initial="hidden"
        animate="visible"
        transition={{ delayChildren: 0.3, staggerChildren: 0.1 }}
      >
        <motion.path
          variants={variants.text}
          d="M0,75.658h31.063v6.326H7.343v15.167h21.664v6.326H7.343v16.173h24.087v6.326H0V75.658z"
        />
        <motion.path
          variants={variants.text}
          d="M76.594,125.976H42.96V75.658h7.343v43.992h26.291V125.976z"
        />
        <motion.path
          variants={variants.text}
          d="M77.402,100.817c0-4.121,0.525-7.799,1.579-11.034c1.052-3.235,2.57-5.966,4.553-8.195 c1.983-2.229,4.406-3.929,7.271-5.104c2.864-1.174,6.107-1.761,9.73-1.761c3.867,0,7.257,0.636,10.171,1.905 c2.912,1.271,5.336,3.044,7.271,5.319c1.933,2.277,3.378,5.021,4.333,8.23c0.955,3.212,1.432,6.757,1.432,10.639 c0,4.122-0.527,7.8-1.579,11.034c-1.053,3.234-2.583,5.966-4.59,8.194c-2.008,2.229-4.443,3.931-7.307,5.104 c-2.864,1.173-6.108,1.761-9.73,1.761c-3.917,0-7.32-0.636-10.208-1.905c-2.889-1.269-5.287-3.042-7.197-5.319 c-1.909-2.275-3.341-5.02-4.296-8.23C77.879,108.245,77.402,104.699,77.402,100.817z M85.186,100.817 c0,2.713,0.306,5.258,0.918,7.637c0.612,2.379,1.542,4.472,2.791,6.279c1.248,1.809,2.838,3.235,4.773,4.282 c1.933,1.046,4.222,1.569,6.866,1.569c4.748,0,8.506-1.594,11.272-4.781c2.766-3.188,4.149-8.182,4.149-14.986 c0-2.664-0.307-5.186-0.918-7.564c-0.613-2.379-1.542-4.471-2.791-6.28c-1.248-1.808-2.84-3.247-4.773-4.317 c-1.935-1.07-4.248-1.606-6.94-1.606c-4.7,0-8.434,1.619-11.199,4.853C86.569,89.137,85.186,94.11,85.186,100.817z"
        />
        <motion.path
          variants={variants.text}
          d="M132.259,100.817c0-4.121,0.525-7.799,1.579-11.034c1.052-3.235,2.57-5.966,4.553-8.195 c1.983-2.229,4.406-3.929,7.271-5.104c2.864-1.174,6.107-1.761,9.73-1.761c3.867,0,7.257,0.636,10.171,1.905 c2.912,1.271,5.336,3.044,7.271,5.319c1.933,2.277,3.378,5.021,4.333,8.23c0.955,3.212,1.432,6.757,1.432,10.639 c0,4.122-0.527,7.8-1.579,11.034c-1.053,3.234-2.583,5.966-4.59,8.194c-2.008,2.229-4.443,3.931-7.307,5.104 c-2.864,1.173-6.108,1.761-9.73,1.761c-3.917,0-7.32-0.636-10.208-1.905c-2.889-1.269-5.287-3.042-7.197-5.319 c-1.909-2.275-3.341-5.02-4.296-8.23C132.736,108.245,132.259,104.699,132.259,100.817z M140.043,100.817 c0,2.713,0.306,5.258,0.918,7.637c0.612,2.379,1.542,4.472,2.791,6.279c1.248,1.809,2.838,3.235,4.773,4.282 c1.933,1.046,4.222,1.569,6.866,1.569c4.748,0,8.506-1.594,11.272-4.781c2.766-3.188,4.149-8.182,4.149-14.986 c0-2.664-0.307-5.186-0.918-7.564c-0.613-2.379-1.542-4.471-2.791-6.28c-1.248-1.808-2.84-3.247-4.773-4.317 c-1.935-1.07-4.248-1.606-6.94-1.606c-4.7,0-8.434,1.619-11.199,4.853C141.426,89.137,140.043,94.11,140.043,100.817z M184.62,138.977c-1.274,0.339-2.546,0.569-3.819,0.69c-1.274,0.121-2.546,0.182-3.819,0.182c-2.693,0-5.324-0.244-7.895-0.732 s-5.043-1.022-7.417-1.604c-2.375-0.582-4.663-1.116-6.866-1.604c-2.203-0.489-4.285-0.732-6.242-0.732 c-1.225,0-2.423,0.144-3.599,0.431v-6.315c1.469-0.294,2.938-0.441,4.406-0.441c2.203,0,4.418,0.247,6.646,0.744 c2.228,0.495,4.48,1.026,6.756,1.593c2.276,0.565,4.626,1.096,7.05,1.592s4.957,0.744,7.601,0.744c1.125,0,2.288-0.061,3.488-0.18 c1.199-0.12,2.435-0.323,3.709-0.611V138.977z"
        />
        <motion.path
          variants={variants.text}
          d="M221.558,75.658h6.902v32.85c0,3.116-0.453,5.799-1.357,8.051c-0.908,2.253-2.193,4.134-3.855,5.644 c-1.666,1.509-3.637,2.623-5.912,3.342c-2.277,0.719-4.811,1.078-7.602,1.078c-6.609,0-11.652-1.401-15.127-4.205 c-3.477-2.803-5.214-6.96-5.214-12.472V75.658h7.343v31.556c0,4.889,1.089,8.292,3.268,10.208c2.178,1.917,5.398,2.875,9.658,2.875 c4.111,0,7.123-1.054,9.031-3.163c1.91-2.108,2.865-5.486,2.865-10.136V75.658z"
        />
        <motion.path
          variants={variants.text}
          d="M241.532,75.658h31.063v6.326h-23.72v15.167h21.664v6.326h-21.664v16.173h24.087v6.326h-31.431V75.658z"
        />
        <motion.path
          variants={variants.text}
          d="M295.214,93.988l-4.406-7.044h-0.294l0.882,7.044v31.988h-6.903V74.939h4.112l26.217,32.634l4.187,6.686h0.367 l-0.882-6.686V75.658h6.903v51.037h-4.112L295.214,93.988z"
        />
        <motion.path
          variants={variants.text}
          d="M374.819,123.82c-1.616,1.149-3.672,1.953-6.169,2.408c-2.497,0.454-5.141,0.683-7.932,0.683 c-3.28,0-6.401-0.516-9.363-1.546c-2.963-1.029-5.569-2.611-7.82-4.744c-2.253-2.132-4.039-4.84-5.361-8.123 c-1.322-3.281-1.982-7.176-1.982-11.681c0-4.696,0.734-8.698,2.203-12.004s3.378-6.002,5.728-8.087 c2.351-2.084,4.994-3.605,7.932-4.564c2.938-0.958,5.85-1.438,8.738-1.438c3.231,0,5.875,0.168,7.932,0.503 c2.057,0.336,3.793,0.767,5.214,1.294l-1.615,6.182c-2.399-1.102-6.047-1.653-10.942-1.653c-2.203,0-4.346,0.348-6.426,1.042 c-2.081,0.695-3.941,1.822-5.581,3.378c-1.641,1.558-2.95,3.594-3.929,6.11c-0.98,2.516-1.469,5.595-1.469,9.237 c0,3.26,0.465,6.123,1.396,8.59c0.929,2.469,2.214,4.529,3.854,6.183s3.573,2.899,5.802,3.737c2.228,0.839,4.663,1.258,7.308,1.258 c2.397,0,4.479-0.215,6.242-0.646c1.762-0.432,3.255-0.981,4.479-1.653L374.819,123.82z"
        />
        <motion.path
          variants={variants.text}
          d="M384.292,75.658h31.063v6.326h-23.72v15.167h21.664v6.326h-21.664v16.173h24.087v6.326h-31.431V75.658z"
        />
      </motion.g>
      <motion.g
        id="SymbolsGroup"
        initial="hidden"
        animate="visible"
        transition={{ delayChildren: 1, staggerChildren: 0.25 }}
      >
        <motion.g id="Symbol1" variants={variants.symbol}>
          <path d="M127.631,18.787V0.076c-10.024,0.736-18.022,8.7-18.807,18.711H127.631z" />
          <path d="M130.651,18.787h18.807c-0.785-10.01-8.783-17.974-18.807-18.711V18.787z" />
          <path d="M127.631,21.807h-18.817c0.692,10.105,8.726,18.173,18.817,18.914V21.807z" />
          <path d="M130.651,21.807v18.914c10.091-0.741,18.126-8.809,18.818-18.914H130.651z" />
        </motion.g>
        <motion.g id="Symbol2" variants={variants.symbol}>
          <path d="M181.02,17.963l13.101-13.101c-7.586-6.455-18.764-6.464-26.359-0.026L181.02,17.963z" />
          <path d="M196.485,7.229l-13.088,13.088l13.228,13.097C202.926,25.829,202.875,14.764,196.485,7.229z" />
          <path d="M178.64,20.343L165.38,7.216c-6.454,7.596-6.45,18.785,0.01,26.377L178.64,20.343z" />
          <path d="M181.017,22.697l-13.26,13.26c7.643,6.483,18.914,6.43,26.504-0.148L181.017,22.697z" />
        </motion.g>
        <motion.g id="Symbol3" variants={variants.symbol}>
          <path d="M225.168,1.783c-7.118,3.184-12.085,10.314-12.085,18.616c0,8.304,4.969,15.435,12.09,18.618L225.168,1.783z" />
          <path d="M241.95,1.857l0.004,37.081c7.032-3.219,11.926-10.3,11.926-18.54C253.881,12.158,248.984,5.076,241.95,1.857z" />
          <path d="M238.766,0.716C237.078,0.264,235.313,0,233.482,0c-1.775,0-3.488,0.25-5.131,0.677l0.005,39.446 c1.641,0.425,3.352,0.675,5.126,0.675c1.833,0,3.6-0.264,5.289-0.717L238.766,0.716z" />
        </motion.g>
        <motion.g id="Symbol4" variants={variants.symbol}>
          <path d="M306.324,20.402c0-1.782-0.251-3.501-0.68-5.149l-39.436-0.002c-0.429,1.646-0.681,3.365-0.681,5.145 c0,1.839,0.265,3.612,0.721,5.307l39.355,0.002C306.058,24.012,306.324,22.24,306.324,20.402z" />
          <path d="M304.551,12.107C301.372,4.978,294.237,0.001,285.928,0c-8.311-0.001-15.448,4.975-18.628,12.104L304.551,12.107z" />
          <path d="M267.375,28.85c3.214,7.042,10.3,11.947,18.547,11.948c8.248,0.001,15.336-4.903,18.552-11.946L267.375,28.85z" />
        </motion.g>
      </motion.g>
    </svg>
  );
};

export default Logo;
