import React, { useState, useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import LeftSidebar from "@/components/Layout/LeftSidebar";
import TopNavbar from "@/components/Layout/TopNavbar";
import Footer from "@/components/Layout/Footer";
import ScrollToTop from "@/components/Layout/ScrollToTop";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
// import ControlPanelModal from "@/componentsControlPanelModal";
import { motion, AnimatePresence } from "framer-motion";
import { NextSeo } from "next-seo";

const Layout = ({ defaultSidebarOpen = false, children }) => {
  const theme = useTheme();

  // const router = useRouter();
  const [active, setActive] = useState(defaultSidebarOpen);

  const toogleActive = () => {
    setActive(!active);
  };

  const isMd = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  React.useEffect(() => {
    if (defaultSidebarOpen) {
      if (isMd) {
        setActive(false);
      } else {
        setActive(true);
      }
    }
  }, [isMd]);

  // const [darkMode, setDarkMode] = useState(false);

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

  //   // Listen for changes to the color scheme preference
  //   const handleChange = (e) => {
  //     // if it's dark -> set dark theme_color
  //     // console.log(e.matches);
  //     setDarkMode(e.matches);
  //     // theme.palette.text.primary = e.matches
  //     //   ? "hsla(0, 0%, 100%, 80%)"
  //     //   : "hsla(0, 0%, 0%, 80%)";
  //     // theme.palette.text.secondary = e.matches
  //     //   ? "hsla(0, 0%, 100%, 60%)"
  //     //   : "hsla(0, 0%, 0%, 60%)";
  //     // if (e.matches) {
  //     //   document
  //     //     .querySelector('meta[name="theme-color"]')
  //     //     .setAttribute("content", "#6b00b3");
  //     // }
  //     // // if it's light -> set light theme_color
  //     // else {
  //     //   document
  //     //     .querySelector('meta[name="theme-color"]')
  //     //     .setAttribute("content", "#a31aff");
  //     // }
  //   };
  //   handleChange(mediaQuery);
  //   mediaQuery.addEventListener("change", handleChange);

  //   return () => {
  //     mediaQuery.removeEventListener("change", handleChange);
  //   };
  // }, []);

  const motionVariants = {
    main: {
      hidden: {
        opacity: 0,
        // x: -50,
        transition: {
          duration: 0.2,
        },
      },
      visible: {
        opacity: 1,
        // x: 0,
        transition: {
          duration: 0.2,
        },
      },
    },
  };

  return (
    <>
      <NextSeo
        themeColor={theme.palette.appBar.main}
        backgroundColor={theme.palette.background.default}
      />
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div
        className={`main-wrapper-content${
          defaultSidebarOpen ? " default-sidebar-open" : ""
        }${active ? " active" : ""}`}
      >
        <TopNavbar toogleActive={toogleActive} />
        <LeftSidebar
          toogleActive={toogleActive}
          active={active}
          enableBackdrop={!defaultSidebarOpen || isMd}
          defaultSidebarOpen={defaultSidebarOpen}
        />
        <div className="page-content">
          <AnimatePresence mode="wait">
            <motion.main
              key={useRouter().pathname}
              variants={motionVariants.main}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ type: "inertia" }}
            >
              {children}
            </motion.main>
          </AnimatePresence>
          <Footer />
        </div>

        {/* ScrollToTop */}
        <ScrollToTop />

        {/* <ControlPanelModal /> */}
      </div>
    </>
  );
};

export default Layout;
