import nl_NL from "@/public/img/languages/nl_NL.webp";
import en_UK from "@/public/img/languages/en_UK.webp";
import ar_EG from "@/public/img/languages/ar_EG.webp";
import fr_FR from "@/public/img/languages/fr_FR.webp";
import de_DE from "@/public/img/languages/de_DE.webp";

const languages = [
  {
    value: "nl_NL",
    label: "Dutch",
    flag: "/flags/nl.svg",
    image: nl_NL,
    alt: "Photo of Dutch buildings and a canal with boats at sunset on a summer day. The buildings are lit up and the sky is light blue.",
  },
  {
    value: "en_UK",
    label: "English",
    flag: "/flags/uk.svg",
    image: en_UK,
    alt: "Photo of Big Ben in London, England. The sky is cloudy and the sun is setting.",
  },
  {
    value: "ar_EG",
    label: "Arabic",
    flag: "/flags/eg.svg",
    image: ar_EG,
    alt: "Photo of the Pyramids of Giza, Egypt.",
  },
  {
    value: "fr_FR",
    label: "French",
    flag: "/flags/fr.svg",
    image: fr_FR,
    alt: "Photo of the Louvre Museum in Paris, France. The sky is blue and the sun is shining.",
  },
  {
    value: "de_DE",
    label: "German",
    flag: "/flags/de.svg",
    image: de_DE,
    alt: "Photo of the Berlin TV Tower behind the houses of Berlin, Germany. The sky is blue and the sun is shining.",
  },
];

export default languages;
