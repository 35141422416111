import React from "react";
// import dynamic from "next/dynamic";
import Stack from "@mui/material/Stack";
// import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import CircularProgress from "@mui/material/CircularProgress";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import AnalyticsContext from "@/utils/AnalyticsProvider";
import BuiltByW from "@/components/Layout/BuiltByW";
// import { SocialIcon } from "react-social-icons";
// const Dialog = dynamic(() => import("@mui/material/Dialog"), { ssr: false });
// const DialogContent = dynamic(() => import("@mui/material/DialogContent"), {
//   ssr: false,
// });
// const DialogActions = dynamic(() => import("@mui/material/DialogActions"), {
//   ssr: false,
// });
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// const ReactMarkdown = dynamic(() => import("markdown-to-jsx"), {
//   ssr: false,
// });
// import ReactMarkdown from "markdown-to-jsx";

// function MarkdownListItem(props) {
//   return <Box component="li" sx={{ mt: 1, typography: "body1" }} {...props} />;
// }

const Footer = () => {
  // const analytics = React.useContext(AnalyticsContext);

  // Initialize state for dialogs showing Terms of Use, Privacy Policy, and Copyright
  // const [openMdDialog, setOpenMdDialog] = React.useState(false);

  // const [mdContentLoading, setMdContentLoading] = React.useState(false);
  // const [mdContent, setMdContent] = React.useState("");
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const mdOptions = {
  //   overrides: {
  //     h1: {
  //       component: Typography,
  //       props: {
  //         gutterBottom: true,
  //         variant: "h4",
  //         component: "h1",
  //         color: "var(--headingColor)",
  //       },
  //     },
  //     h2: {
  //       component: Typography,
  //       props: {
  //         gutterBottom: true,
  //         variant: "h6",
  //         component: "h2",
  //         color: "var(--headingColor)",
  //       },
  //     },
  //     h3: {
  //       component: Typography,
  //       props: { gutterBottom: true, variant: "subtitle1" },
  //     },
  //     h4: {
  //       component: Typography,
  //       props: {
  //         gutterBottom: true,
  //         variant: "caption",
  //         paragraph: true,
  //       },
  //     },
  //     p: {
  //       component: Typography,
  //       props: { paragraph: true },
  //     },
  //     a: { component: Link },
  //     li: {
  //       component: MarkdownListItem,
  //     },
  //   },
  // };

  // const loadMdContent = (mdContentFile) => {
  //   // analytics.action("view_policy", mdContentFile);
  //   // setMdContentLoading(true);
  //   // fetch(`md/${mdContentFile}`)
  //   //   .then((response) => response.text())
  //   //   .then((text) => {
  //   //     setMdContent(text);
  //   //     setMdContentLoading(false);
  //   //   });
  // };

  // const handleCloseMdDialog = () => {
  //   setOpenMdDialog(false);
  //   setMdContent("");
  // };

  // const handleOpenTerms = () => {
  //   loadMdContent("terms-of-use.md");
  //   setOpenMdDialog(true);
  // };

  // const handleOpenPrivacy = () => {
  //   loadMdContent("privacy-policy.md");
  //   setOpenMdDialog(true);
  // };

  // const handleOpenCopyright = () => {
  //   loadMdContent("copyright-policy.md");
  //   setOpenMdDialog(true);
  // };

  return (
    <footer>
      <Stack className="footer p-6 text-center">
        {/* <Box className="links"> */}
        <Grid container spacing={0}>
          {/* <Grid item xs={12} md={6} lg={3} xl={3} textAlign={"left"} p={1}>
            <Link
              // href="/terms-of-use"
              // target="_blank"
              underline="none"
              // rel="noreferrer"
              // className="px-5px"
              onClick={handleOpenTerms}
              sx={{ cursor: "pointer" }}
            >
              <Typography textAlign={"left"}>Terms of Use</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3} textAlign={"left"} p={1}>
            <Link
              // target="_blank"
              underline="none"
              // className="px-5px"
              onClick={handleOpenPrivacy}
              sx={{ cursor: "pointer" }}
            >
              <Typography textAlign={"left"}>Privacy Policy</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3} textAlign={"left"} p={1}>
            <Link
              // target="_blank"
              underline="none"
              // className="px-5px"
              onClick={handleOpenCopyright}
              sx={{ cursor: "pointer" }}
            >
              <Typography textAlign={"left"}>Copyright Policy</Typography>
            </Link>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={3} xl={3} textAlign={"left"} p={1}>
            <Stack spacing={1} direction="row">
              <SocialIcon
                url="https://www.facebook.com/jaxenai"
                fgColor="white"
                style={{ height: 25, width: 25 }}
              />
              <SocialIcon
                url="https://www.instagram.com/jaxen.ai/"
                fgColor="white"
                style={{ height: 25, width: 25 }}
              />
              <SocialIcon
                url="https://www.youtube.com/@Jaxen-ai"
                fgColor="white"
                style={{ height: 25, width: 25 }}
              />
              <SocialIcon
                url="https://twitter.com/itsJaxen"
                fgColor="white"
                style={{ height: 25, width: 25 }}
              />
              <SocialIcon
                url="https://www.tiktok.com/@jaxen.ai"
                fgColor="white"
                style={{ height: 25, width: 25 }}
              />
              <SocialIcon
                url="https://www.threads.net/@jaxen.ai"
                fgColor="#eee"
                bgColor="#000"
                style={{ height: 25, width: 25 }}
                defaultSVG={{
                  icon: "M 0,0 H 64 V 64 H 0 Z",
                  mask: "M40.2,30.7c-0.2-0.1-0.3-0.1-0.5-0.2c-0.3-5-3-7.8-7.5-7.8c0,0,0,0-0.1,0c-2.7,0-5,1.2-6.4,3.3 l2.5,1.7c1-1.6,2.7-1.9,3.9-1.9c0,0,0,0,0,0c1.5,0,2.6,0.4,3.4,1.3c0.5,0.6,0.9,1.5,1.1,2.5c-1.3-0.2-2.8-0.3-4.3-0.2 c-4.3,0.2-7.1,2.8-6.9,6.3c0.1,1.8,1,3.3,2.5,4.3c1.3,0.8,2.9,1.3,4.6,1.2c2.3-0.1,4-1,5.3-2.6c0.9-1.2,1.5-2.7,1.8-4.7 c1.1,0.6,1.9,1.5,2.3,2.5c0.7,1.7,0.8,4.6-1.6,7c-2.1,2.1-4.5,2.9-8.3,3c-4.1,0-7.3-1.4-9.3-3.9C20.9,40,20,36.5,19.9,32 c0-4.5,1-8,2.9-10.4c2-2.6,5.2-3.9,9.3-3.9c4.2,0,7.4,1.4,9.5,4c1,1.3,1.8,2.9,2.3,4.7l2.9-0.8c-0.6-2.3-1.6-4.3-2.9-5.9 c-2.7-3.3-6.7-5-11.8-5.1h0c-5.1,0-9,1.7-11.6,5.1c-2.3,3-3.5,7.1-3.6,12.3l0,0l0,0c0,5.2,1.2,9.3,3.6,12.3 c2.6,3.3,6.5,5.1,11.6,5.1h0c4.5,0,7.7-1.2,10.3-3.8c3.4-3.4,3.3-7.7,2.2-10.4C43.9,33.3,42.4,31.7,40.2,30.7z M32.4,38.1 c-1.9,0.1-3.9-0.7-4-2.6c-0.1-1.4,1-2.9,4.1-3c0.4,0,0.7,0,1.1,0c1.1,0,2.2,0.1,3.2,0.3C36.4,37.2,34.3,38,32.4,38.1z",
                  color: "#000000",
                }}
              />
            </Stack>
          </Grid> */}
          {/* Add new items here, DONT FORGET TO CHANGE XL back to 3!!! */}
          <Grid item xs={12} p={1}>
            <Typography textAlign={"left"}>
              ©2023 Eloquence Language Learning. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      {/* {openMdDialog && (
        <Dialog
          open={openMdDialog}
          onClose={handleCloseMdDialog}
          fullScreen={fullScreen}
          maxHeight={"70vh"}
          PaperProps={{
            sx: {
              maxHeight: { xs: "100%", sm: "70vh" },
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseMdDialog}
            variant="contained"
            color="secondary"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              minWidth: { xs: 0, sm: "400px" },
              minHeight: { xs: 0, sm: "400px" },
              paddingX: "30px",
            }}
          >
            {mdContentLoading ? (
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "calc(100vh - 40px)", sm: "360px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress m="auto" />
              </Box>
            ) : (
              <ReactMarkdown
                options={mdOptions}
                sx={{
                  paddingX: "10px",
                  paddingY: "20px",
                }}
              >
                {mdContent}
              </ReactMarkdown>
            )}
          </DialogContent>

          {!mdContentLoading && (
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseMdDialog}
              >
                Close
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )} */}
      <BuiltByW />
    </footer>
  );
};

export default Footer;
