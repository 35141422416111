// Context Provider for Language Selection and Path Selection
import React from "react";
import { useCookies } from "react-cookie";

import languages from "@/data/languages";
import paths from "@/data/paths";

const SelectionContext = React.createContext();

export const SelectionProvider = ({ children }) => {
  const [language, setLanguage] = React.useState(null);
  const [path, setPath] = React.useState(null);

  const [cookies, setCookie] = useCookies(["language", "path"]);
  React.useEffect(() => {
    if (cookies.language) {
      setLanguage(languages.find((l) => l.value === cookies.language));
    }
    if (cookies.path) {
      setPath(paths.find((p) => p.id.toString() === cookies.path));
    }
  }, [cookies]);

  React.useEffect(() => {
    if (language) {
      setCookie("language", language.value, { path: "/" });
    }
  }, [language, setCookie]);

  React.useEffect(() => {
    if (path) {
      setCookie("path", path.id, { path: "/" });
    }
  }, [path, setCookie]);

  const value = {
    language,
    setLanguage,
    path,
    setPath,
  };

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionContext;
