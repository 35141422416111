import React from "react";
import { ThemeProvider, CssBaseline, useMediaQuery } from "@mui/material";
import themes from "@/styles/themes";
import { useLocalStorage } from "usehooks-ts";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import { Tooltip } from "@mui/material";

const ThemeContext = React.createContext();

const MultiThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState("light");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersHighContrast = useMediaQuery("(prefers-contrast: more)");
  const [themePreference, setThemePreference] = useLocalStorage(
    "theme",
    "auto"
  );
  const [systemPreference, setSystemPreference] = React.useState("light");

  // React.useEffect(() => {
  //   setThemePreference(themePreference);
  // }, []);

  // Monitor system preference
  React.useEffect(() => {
    if (prefersDarkMode) {
      setSystemPreference("dark");
    } else if (prefersHighContrast) {
      setSystemPreference("high-contrast");
    } else {
      setSystemPreference("light");
    }
  }, [prefersDarkMode, prefersHighContrast]);

  // Monitor theme preference and update theme accordingly
  React.useEffect(() => {
    if (themePreference === "auto") {
      console.log("Setting Theme: " + systemPreference);
      setTheme(systemPreference);
    } else {
      console.log("Setting Theme: " + themePreference);
      setTheme(themePreference);
    }
    document.getElementsByTagName("html")[0].className = themePreference;
  }, [themePreference, systemPreference]);

  const data = {
    themePreference: themePreference,
    setThemePreference: setThemePreference,
  };

  return (
    <ThemeContext.Provider value={data}>
      <ThemeProvider theme={themes[theme].theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const ThemeSelector = () => {
  const { themePreference, setThemePreference } =
    React.useContext(ThemeContext);

  const buttonData = Object.keys(themes).map((key) => {
    return {
      value: key,
      name: themes[key].name,
      icon: themes[key].icon,
      theme: themes[key].theme,
    };
  });

  const handleChange = (event) => {
    setThemePreference(event.target.value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={themePreference}
      exclusive
      onChange={handleChange}
      aria-label="Select Theme"
    >
      {buttonData.map((button) => (
        <Tooltip key={button.value} value={button.value} title={button.name}>
          <ToggleButton value={button.value}>
            <button.icon value={button.value} className="pointer-events-none" />
          </ToggleButton>
        </Tooltip>
      ))}
      Testing
    </ToggleButtonGroup>
  );
};

export { MultiThemeProvider };
export default ThemeSelector;
