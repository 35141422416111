import React from "react";
import Home from "@mui/icons-material/Home";
// import Science from "@mui/icons-material/Science";
import SchoolIcon from "@mui/icons-material/School";
import QuizIcon from "@mui/icons-material/Quiz";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <Home />,
    // iconClosed: <KeyboardArrowRightIcon />,
    // iconOpened: <KeyboardArrowDownIcon />,

    // subNav: [
    //   {
    //     title: "eCommerce",
    //     path: "/ecommerce/",
    //   },
    //   {
    //     title: "Analytics",
    //     path: "/analytics/",
    //   },
    //   {
    //     title: "Project Management",
    //     path: "/project-management/",
    //   },
    //   {
    //     title: "LMS Courses",
    //     path: "/lms-courses/",
    //   },
    // ],
  },
  {
    title: "Courses",
    path: "/Courses",
    icon: <SchoolIcon />,
    // requiresSubscription: true,

    // subNav: [
    //   {
    //     title: "User Manual",
    //     path: "/UserManual",
    //   },
    // {
    //   title: "Analytics",
    //   path: "/analytics/",
    // },
    // {
    //   title: "Project Management",
    //   path: "/project-management/",
    // },
    // {
    //   title: "LMS Courses",
    //   path: "/lms-courses/",
    // },
    // ],
  },
  // {
  //   title: "Pricing",
  //   path: "/Courses",
  //   hash: "pricing",
  //   icon: <AttachMoneyIcon />,
  //   //   iconClosed: <KeyboardArrowRightIcon />,
  //   //   iconOpened: <KeyboardArrowDownIcon />,

  //   //   // subNav: [
  //   //   //   {
  //   //   //     title: "eCommerce",
  //   //   //     path: "/ecommerce/",
  //   //   //   },
  //   //   //   {
  //   //   //     title: "Analytics",
  //   //   //     path: "/analytics/",
  //   //   //   },
  //   //   //   {
  //   //   //     title: "Project Management",
  //   //   //     path: "/project-management/",
  //   //   //   },
  //   //   //   {
  //   //   //     title: "LMS Courses",
  //   //   //     path: "/lms-courses/",
  //   //   //   },
  //   //   // ],
  // },
  {
    title: "Book a placement test",
    path: "/BookTest",
    icon: <QuizIcon />,
  },
  // {
  //   title: "Contact Us",
  //   path: "/Contact",
  //   icon: <ContactSupportIcon />,
  // },
];
