import business from "@/public/img/paths/business.webp";
import passport from "@/public/img/paths/passport.webp";
import fluency from "@/public/img/paths/fluency.webp";
import accent from "@/public/img/paths/accent.webp";

const paths = [
  {
    id: 2,
    image: business,
    title: "Business Communication",
    description:
      "Develop language competence specifically tailored to business settings, including negotiation, presentations, and professional interactions.",
    levels: [1, 2, 3, 4, 5, 6],
  },
  {
    id: 4,
    image: passport,
    title: "Naturalization and Citizenship",
    description:
      "Focus on language proficiency required for naturalization or citizenship exams and daily life in a new country.",
    levels: [1, 2, 3, 4, 101],
  },
  {
    id: 6,
    image: fluency,
    title: "Fluency in Communication",
    description:
      "Achieve overall language fluency and advanced communication skills for personal, academic, or professional purposes.",
    levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  },
  {
    id: 7,
    image: accent,
    title: "Accent Reduction",
    description:
      "Improve pronunciation and reduce accent to enhance overall communication skills.",
    levels: [1, 2, 3, 4, 102],
  },
];

export default paths;
